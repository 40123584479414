<!--AI+物联网故障历史页面-->
<template>
  <div :title="pageTitle" :breadcrumb="breadcrumb">
    <div v-if="false" class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <!-- <a-form-model-item label="电梯注册码" prop="liftnum">
              <a-input v-model.trim="queryParams.liftnum" placeholder="请输入"></a-input>
            </a-form-model-item> -->
            <a-form-model-item label="救援码" prop="liftrescue">
              <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <!-- <a-form-model-item label="地址" prop="adress">
              <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
            </a-form-model-item> -->
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <!-- <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button> -->
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" :style="liftid?'':'margin-top: 10px; padding: 0;'">
      <div class="box-container-inner" :style="liftid?'':'padding: 0;'">
        <!-- <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div> -->
        <a-table :columns="tableColumns" :data-source="tableData" row-key="eventrecordid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="eventname" :title="value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''" slot-scope="value, record">{{value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''}}</span>
          <span slot="eventstatus" slot-scope="value">
            <a-tag :color="eventstatusColors[value]||''">{{eventstatus[value]||''}}</a-tag>
          </span>
          <span slot="starttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="eventendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="check" slot-scope="value, record">
            <a-button type="link" size="small" @click="checkCalllog(value,record)">播放</a-button>
          </span>
          <span slot="image" slot-scope="value, record">
            <a-button type="link" size="small" @click="lookImage(value,record)">图片</a-button>
          </span>
          <span slot="recover" slot-scope="value, record">
            <a-button type="link" size="small" @click="recover(value,record)">
              <span>回放</span>
<!--              <span>{{ moment(new Date()).format("MM")-(record.eventstarttime).substring(4,6)>0?(parseInt(moment(new Date()).format("DD"))+30-parseInt((record.eventstarttime).substring(6,8))>15?(value=""):(value="回放")):(moment(new Date()).format("YYYYMMDD")-(record.eventstarttime).substring(0,8)>15?(value=""):(value="回放"))}}</span>-->
            </a-button>
          </span>
          <span slot="transform" slot-scope="value, record">
            <a-button v-if="record.eventcode=='06'&&record.eventtype==3&&record.isturned!='1'" type="link" size="small" @click="transform(value,record)">转为困人</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" @showSizeChange="onShowSizeChange" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <imageBack :visible.sync="imageBackVisible" ref="imageBack" :EventData="eventData"></imageBack>
    <checkTraceBack :visible.sync="checkTraceVisible" ref="checkTrace" :Datadetail="detaildata"></checkTraceBack>
  </div>
</template>

<script>
import { getItemFromArrayByKey, getTimeRange } from 'U'
import store from '@/store'
import { mapState } from 'vuex'
import moment from 'moment'
import imageBack from "V/facilitiesEquipment/elevator/elevatorArchives/timeMonitoring/imageBack";
import pagination from '@/mixins/pagination'
import approvestatusMap from '@/json/approvestatus'
import approvestatuscolors from '@/json/approvestatuscolors'
import {titleLists,eventTypes,eventstatus,eventstatusColors} from '@/json/wlwhistory'
import { getMentenacegroupListByCondition } from 'A/xtpz.js'
import {getLiftEventrecordListByCondition, deleteLiftEventrecord, modifyLiftEventrecord} from 'A/wlw.js'
import checkTraceBack from "V/abnormalHistory/wlwbj/main/checkTraceBack";
import {addLiftEventrecord} from "../../../../../api/wlw";
export default {
  name: 'wlwBasicPart',
  mixins: [pagination],
  components: {
    imageBack,
    checkTraceBack
  },
  props: ['pageType', 'devicenum', 'liftid'],
  data() {
    return {
      moment,
      disabled:false,
      imageBackVisible:false,
      eventData:'',
      eventstatus,
      eventstatusColors,
      approvestatusMap,
      approvestatuscolors,
      showAdvanced: false,
      queryParams: {
        liftnum: '',
        liftrescue: '',
        userdepid: '',
        maintenancedepid: '',
        maintenancegroupid: '',
        adress: '',
        areacode: '',
        starttime: '',
        endtime: '',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      maintenancedepidCascaderSelected: [],
      maintenancegroupidOptions: [],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      operationLiftid: '',
      // checkModalVisible: false,
      checklistModalVisible: false,
      examineModalVisible: false,
      examinelistModalVisible: false,
      pagination: {
        total: 0,
        pageSize: this.liftid ? 10 : 5,
      },
      checkTraceVisible:false,
      detaildata:'',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    eventtype(){
      return eventTypes[this.pageType]
    },
    pageTitle() {
      return titleLists[this.pageType]
    },
    tableColumns(){
      return [
        {
          title: '故障名称',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
          scopedSlots: { customRender: 'eventname' }
        },
        {
          title: '故障时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'starttime' }
        },
        {
          title: '解除时间',
          dataIndex: 'eventendtime',
          key: 'eventendtime',
          ellipsis: true,
          scopedSlots: { customRender: 'eventendtime' }
        },
        {
          title: '处理状态',
          dataIndex: 'eventstatus',
          key: 'eventstatus',
          ellipsis: true,
          width:70,
          scopedSlots: { customRender: 'eventstatus' }
        },
        ...(this.pageType==='alarm')?[{
          title: '录音',
          align:'center',
          width:50,
          scopedSlots: { customRender: 'check' }
        }]:[],
        {
          title: '图片',
          align:'center',
          width:50,
          scopedSlots: { customRender: 'image' }
        },
        {
          title: '回放',
          align:'center',
          width:50,
          scopedSlots: { customRender: 'recover' }
        },
        ...(this.pageType==='event')?[{
          title: '困人转化',
          align:'center',
          width:66,
          scopedSlots: { customRender: 'transform' }
        }]:[],
      ]
    },
    breadcrumb() {
      const pages = [{name:titleLists.main,path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch: {
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
      }else {
        this.queryParams.userdepid = '';
      }
    },
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.maintenancedepid = val[val.length-1];
        this.initMaintenancegroupidOptions();
      }else {
        this.queryParams.maintenancedepid = '';
        this.maintenancegroupidOptions = [];
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    const keepAlivePages = ['elevatorArchivesDetail', 'timeMonitoring'];
    if(!toTab || keepAlivePages.indexOf(from.name)<0) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = [];
      this.userdepidCascaderSelected = [];
      this.maintenancedepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        eventtype:this.eventtype,
        devicenum:this.devicenum||'',
        liftid:this.liftid||'',
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getLiftEventrecordListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    handleAscend(value,record){
      //console.log(value,record)
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.eventrecordid);
      }).catch(()=>{
      });
    },
    delete(eventrecordid) {
      this.showLoading();
      if(eventrecordid) {
        let params = {
          eventrecordid
        };
        deleteLiftEventrecord(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      if(type == 'detail') {
        let prePages = [
          {
            name: '基础功能',
            path: ''
          }
        ];
        if(this.pageType == 'draft') {
          prePages.push({
            name: '电梯草稿',
            path: 'elevatorDraft'
          })
        }else {
          prePages.push({
            name: '电梯档案',
            path: 'elevatorArchives'
          })
        }
        this.$router.push({
          name: 'elevatorArchivesDetail',
          params: {
            liftid: record.liftid,
            prePages: prePages
          }
        })
      }else {
        this.modalShowType = type;
        if(type == 'add') {
          this.modalDetailData = null;
        }else {
          this.modalDetailData = record;
        }
        this.modalVisible = true;
      }
    },
    initMaintenancegroupidOptions() {
      let params = {
        maintenancedepid: this.queryParams.maintenancedepid,
      }
      // getMentenacegroupListByCondition(params).then(res => {
      //   if(res && res.returncode == '0') {
      //     this.maintenancegroupidOptions = res.item;
      //   }
      // })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let liftid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'liftid', liftid);
      if(type == 'detail') {
        this.showModal('detail', liftid, record)
      }else if(type == 'monitoring') {
        let prePages = [
          {
            name: '基础功能',
            path: ''
          }
        ];
        if(this.pageType == 'draft') {
          prePages.push({
            name: '电梯草稿',
            path: 'elevatorDraft'
          })
        }else {
          prePages.push({
            name: '电梯档案',
            path: 'elevatorArchives'
          })
        }
        this.$router.push({
          name: 'timeMonitoring',
          params: {
            liftid: record.liftid,
            prePages: prePages
          }
        })
      }else if(type == 'edit') {
        this.showModal('edit', liftid, record)
      }else if(type == 'delete') {
        this.deleteConfirm(liftid, record)
      }else {
        this.operationLiftid = liftid;
        this[type+'ModalVisible'] = true;
      }
    },
    checkCalllog(value,record){
      this.$refs.checkTrace.queryParams.caller = record.ipnetnum
      this.detaildata=record;
      this.checkTraceVisible = true
    },
    lookImage(value,record){
      this.eventData=record;
      this.imageBackVisible=true;
    },
    recover(value,record){
      if(record.eventendtime==''||record.eventendtime==null){
        this.eventendtime=moment(new Date()).format('YYYYMMDDHHmmss');
        let time = {
          eventrecordid: record.eventrecordid,
          devicenum:record.devicenum,
          starttime:record.eventstarttime,
          endtime: this.eventendtime,
        }
        this.$emit('upDate',time)
      }else{
        let time = {
          eventrecordid: record.eventrecordid,
          devicenum:record.devicenum,
          starttime:record.eventstarttime,
          endtime: record.eventendtime,
        }
        this.$emit('upDate',time)
      }
    },
    transform(value,record){
      let params={
        devicenum:record.devicenum,
        eventcode:'100',
        eventtype:1,
        eventstarttime:record.eventstarttime,
        eventendtime:record.eventendtime,
        eventstatus: 2,
      }
      addLiftEventrecord(params).then(res=>{
        if(res&&res.returncode=='0'){
          modifyLiftEventrecord({eventrecordid:record.eventrecordid,devicenum:record.devicenum,eventcode:record.eventcode,eventtype:record.eventtype,isturned:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.$message.success("转化成功")
              this.getTableData()
            }
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>